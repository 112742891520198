
import { Landscape, OrganizationUser, PermissionType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import contains from '@/helpers/contains'
import { AlertModule } from '@/modules/alert/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { UserModule } from '@/modules/user/store'

import UserInviteDialog from '../components/user-invite-dialog.vue'
import UserRemoveDialog from '../components/user-remove-dialog.vue'
import * as analytics from '../helpers/analytics'
import { OrganizationModule } from '../store'

@Component({
  components: {
    UserInviteDialog,
    UserRemoveDialog
  },
  name: 'OrganizationMembers'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  userModule = getModule(UserModule, this.$store)

  loadingBillingLink = false

  searchTerm = ''
  updatingUserIdPermission: string | null = null
  updatingUserIdLandscapePermissions: string | null = null
  deletingUserId: string | null = null

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.$params.organizationId)!
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get organizationUsersListStatus () {
    return this.organizationModule.organizationUsersListStatus
  }

  get landscapesListStatus () {
    return this.landscapeModule.landscapesListStatus
  }

  get userId () {
    return this.userModule.user?.id
  }

  get landscapes () {
    return this.landscapeModule.landscapes
  }

  get organizationUsers () {
    return Object
      .entries(this.organizationModule.organizationUsers?.[this.currentOrganization.id] || {})
      .map(([id, o]) => {
        let landscapes: Landscape[]
        if (o.landscapePermissions) {
          landscapes = Object
            .entries(o.landscapePermissions)
            .filter(([, permission]) => permission)
            .map(([id]) => this.landscapes.find(o => o.id === id))
            .filter((o): o is Landscape => !!o)
        } else {
          landscapes = this.landscapes
        }
        return {
          ...o,
          id,
          landscapes
        }
      })
  }

  get currentOrganizationUser () {
    return this.organizationUsers.find(o => o.id === this.userId)
  }

  get usedSeats () {
    return this.organizationUsers.filter(o => contains(o.permission, ['admin', 'write'])).length || 0
  }

  get totalSeats () {
    return this.currentOrganizationLimits.seats
  }

  get emptySeats () {
    return this.organizationUsers ? Math.max(0, this.totalSeats - this.usedSeats) : 0
  }

  get headers () {
    return [
      {
        text: 'Name',
        value: 'name',
        width: '15%'
      },
      {
        text: 'Email',
        value: 'email',
        width: '25%'
      },
      {
        sort: (a: PermissionType, b: PermissionType) => {
          const order: PermissionType[] = ['admin', 'billing', 'write', 'read']
          return a === b ? 0 : order.indexOf(a) > order.indexOf(b) ? 1 : -1
        },
        text: 'Permission',
        value: 'permission',
        width: '15%'
      },
      {
        sortable: false,
        text: 'Landscape access',
        value: 'landscapePermissions',
        width: '15%'
      },
      {
        text: 'Last active',
        value: 'lastActiveAt',
        width: '15%'
      },
      {
        align: 'end',
        text: '',
        value: 'actions',
        width: '15%'
      }
    ]
  }

  get permissionTypes () {
    return [
      {
        icon: '$fas-eye',
        text: 'Viewer',
        value: 'read'
      },
      {
        icon: '$fas-user-edit',
        text: 'Editor',
        value: 'write'
      },
      {
        icon: '$fas-user-cog',
        text: 'Admin',
        value: 'admin'
      },
      {
        icon: '$fas-credit-card',
        text: 'Billing',
        value: 'billing'
      }
    ]
  }

  mounted () {
    analytics.organizationMembersScreen.track(this, {
      organizationId: [this.currentOrganization.id]
    })

    if (this.organizationUsersListStatus.idle || (this.organizationUsersListStatus.success && this.organizationUsersListStatus.successInfo.organizationId !== this.currentOrganization.id)) {
      this.organizationModule.organizationUsersList(this.currentOrganization.id)
    }
    if (this.landscapesListStatus.idle || (this.landscapesListStatus.success && this.landscapesListStatus.successInfo.organizationId !== this.currentOrganization.id)) {
      this.landscapeModule.landscapesList(this.currentOrganization.id)
    }
  }

  async updateOrganizationUserPermission (organizationUser: this['organizationUsers'][0], permission: PermissionType) {
    this.updatingUserIdPermission = organizationUser.id
    try {
      const body: OrganizationUser = {
        landscapePermissions: organizationUser.landscapePermissions,
        permission: organizationUser.permission
      }

      body.permission = permission
      if (permission === 'admin') {
        delete body.landscapePermissions
      }

      await this.organizationModule.organizationUserUpdate({
        body,
        organizationId: this.currentOrganization.id,
        userId: organizationUser.id
      })
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.body?.message || err.message
      })
    } finally {
      this.updatingUserIdPermission = null
    }
  }

  async updateOrganizationUserLandscapePermissions (organizationUser: this['organizationUsers'][0], landscapeId?: string) {
    this.updatingUserIdLandscapePermissions = organizationUser.id
    try {
      const body: OrganizationUser = {
        landscapePermissions: organizationUser.landscapePermissions,
        permission: organizationUser.permission
      }

      if (landscapeId) {
        if (body.landscapePermissions) {
          if (body.landscapePermissions[landscapeId]) {
            delete body.landscapePermissions[landscapeId]
          } else {
            body.landscapePermissions[landscapeId] = true
          }
        } else {
          body.landscapePermissions = this.landscapes.reduce<Record<string, boolean>>((p, c) => {
            if (c.id === landscapeId) {
              return p
            } else {
              return {
                ...p,
                [c.id]: true
              }
            }
          }, {})
        }
      } else if (body.landscapePermissions) {
        body.landscapePermissions = undefined
      } else {
        body.landscapePermissions = {}
      }

      await this.organizationModule.organizationUserUpdate({
        body,
        organizationId: this.currentOrganization.id,
        userId: organizationUser.id
      })
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.body?.message || err.message
      })
    } finally {
      this.updatingUserIdLandscapePermissions = null
    }
  }

  async deleteUser (userId: string) {
    this.deletingUserId = userId
    try {
      await this.organizationModule.organizationUserDelete({
        organizationId: this.currentOrganization.id,
        userId
      })
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.body.message
      })
    } finally {
      this.deletingUserId = null
    }
  }

  async editBilling () {
    try {
      if (this.loadingBillingLink) {
        return
      }
      this.loadingBillingLink = true

      const returnUrl = new URL(window.location.href)
      returnUrl.searchParams.set('billing_organization', this.currentOrganization.id)

      const links = await this.organizationModule.organizationBillingLinkCreate({
        organizationId: this.currentOrganization.id,
        returnUrl: returnUrl.toString()
      })

      analytics.organizationBillingLink.track(this, {
        organizationId: [this.currentOrganization.id]
      })

      await new Promise(resolve => setTimeout(resolve, 1000))

      window.location.href = links.updateSubscriptionPlanUrl || links.url
    } catch (err: any) {
      this.loadingBillingLink = false
      this.alertModule.pushAlert({
        color: 'error',
        message: err.body.message
      })
    }
  }
}
