
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'

import * as analytics from '../helpers/analytics'
import { OrganizationModule } from '../store'

@Component({
  components: {
    Dialog
  },
  name: 'OrganizationUserRemoveDialog'
})
export default class extends Vue {
  organizationModule = getModule(OrganizationModule, this.$store)

  message = ''
  userId: string | undefined

  @Ref() readonly dialog!: Dialog

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.$params.organizationId)!
  }

  open (userId: string, message: string) {
    this.userId = userId
    this.message = message
    this.dialog.open()
  }

  close () {
    this.dialog.close()
  }

  opened () {
    analytics.organizationUserRemoveDialog.track(this, {
      organizationId: [this.currentOrganization.id]
    })
  }
}
